// 产品信息，要改找对应的文字，图存储在public文件下
export const pro = [
    {
        name: '螺母', //中文名
        key: '1',
        english: 'Nut', //翻译英文
        url: require('@/assets/pro/1/Nut.jpg'),
        detail: [
            {
                name: '调整螺母2401053-4E', //产品大类页name
                english: 'Adjust Nut 2401053-4E',
                url: require('@/assets/pro/1/Adjust_Nut_2401053-4E/Adjust_Nut_2401053_4E_ATEEN0523.jpg'), // 产品大类页主图url
                paramsUrl: require('@/assets/pro/1/Adjust_Nut_2401053-4E/Adjust_Nut_2401053_4E_13_33_40.png'), //详情页产品参数图
                detailImgUrl: [require('@/assets/pro/1/Adjust_Nut_2401053-4E/Adjust_Nut_2401053_4E_ATEEN0522.jpg'), require('@/assets/pro/1/Adjust_Nut_2401053-4E/Adjust_Nut_2401053_4E_ATEEN0523.jpg')],// 详情页产品图组
            },
            {
                name: '螺母W2502166F01C',
                english: 'Nut W2502166F01C',
                url: require('@/assets/pro/1/Nut_W2502166F01C/Nut_W2502166F01C_ATEEN32903.jpg'),
                paramsUrl: require("@/assets/pro/1/Nut_W2502166F01C/Nut_W2502166F01C_13_36_52.png"),
                detailImgUrl: [require('@/assets/pro/1/Nut_W2502166F01C/Nut_W2502166F01C_ATEEN32903.jpg'),require('@/assets/pro/1/Nut_W2502166F01C/Nut_W2502166F01C_ATEEN0511.jpg'),require('@/assets/pro/1/Nut_W2502166F01C/Nut_W2502166F01C_ATEEN0512.jpg'),]
            },
            {
                name: '螺母WG4075418015',
                english: 'Nut WG4075418015',
                url: require('@/assets/pro/1/Nut_WG4075418015/Nut_WG4075418015_ATEEN41733.jpg'),
                paramsUrl: require("@/assets/pro/1/Nut_WG4075418015/Nut_WG4075418015_13_37_33.png"),
                detailImgUrl: [require('@/assets/pro/1/Nut_WG4075418015/Nut_WG4075418015_ATEEN41733.jpg'),require('@/assets/pro/1/Nut_WG4075418015/Nut_WG4075418015_ATEEN0525.jpg'),require('@/assets/pro/1/Nut_WG4075418015/Nut_WG4075418015_ATEEN0524.jpg'),]
            },
            {
                name: '锁紧螺母DZ9112342047',
                english: 'Locknut DZ9112342047',
                url: require('@/assets/pro/1/Locknut_DZ9112342047/Locknut_DZ9112342047_ATEEN0530.jpg'),
                paramsUrl: require("@/assets/pro/1/Locknut_DZ9112342047/Locknut_DZ9112342047_13_35_54.png"),
                detailImgUrl: [require('@/assets/pro/1/Locknut_DZ9112342047/Locknut_DZ9112342047_ATEEN0530.jpg'),require('@/assets/pro/1/Locknut_DZ9112342047/Locknut_DZ9112342047_ATEEN0528.jpg'),]
            },
            {
                name: '锁紧螺母DZ90149346003',
                english: 'Locknut DZ90149346003',
                url: require('@/assets/pro/1/Locknut_DZ90149346003/Locknut_DZ90149346003_ATEEN0521.jpg'),
                paramsUrl: require("@/assets/pro/1/Locknut_DZ90149346003/Locknut_DZ90149346003_13_31_53.png"),
                detailImgUrl: [require('@/assets/pro/1/Locknut_DZ90149346003/Locknut_DZ90149346003_ATEEN0521.jpg'),require('@/assets/pro/1/Locknut_DZ90149346003/Locknut_DZ90149346003_ATEEN0519.jpg'),]
            },
            {
                name: '锁紧螺母HD90009341405',
                english: 'Locknut HD90009341405',
                url: require('@/assets/pro/1/Locknut_HD90009341405/Locknut_HD90009341405_ATEEN0515.jpg'),
                paramsUrl: require("@/assets/pro/1/Locknut_HD90009341405/Locknut_HD90009341405_18_16_16.png"),
                detailImgUrl: [require('@/assets/pro/1/Locknut_HD90009341405/Locknut_HD90009341405_ATEEN0514.jpg'),require('@/assets/pro/1/Locknut_HD90009341405/Locknut_HD90009341405_ATEEN0515.jpg'),]
            },
            {
                name: '锁紧螺母HD90149346160',
                english: 'Locknut HD90149346160',
                url: require('@/assets/pro/1/Locknut_HD90149346160/Locknut_HD90149346160_ATEEN0527.jpg'),
                paramsUrl: require("@/assets/pro/1/Locknut_HD90149346160/Locknut_HD90149346160_13_34_35.png"),
                detailImgUrl: [require('@/assets/pro/1/Locknut_HD90149346160/Locknut_HD90149346160_ATEEN0526.jpg'),require('@/assets/pro/1/Locknut_HD90149346160/Locknut_HD90149346160_ATEEN0527.jpg'),]
            },
            {
                name: '锁紧螺母WG4005415534',
                english: 'Locknut WG4005415534',
                url: require('@/assets/pro/1/Locknut_WG4005415534/Locknut_WG4005415534_ATEEN32906.jpg'),
                paramsUrl: require("@/assets/pro/1/Locknut_WG4005415534/Locknut_WG4005415534_13_37_10.png"),
                detailImgUrl: [require('@/assets/pro/1/Locknut_WG4005415534/Locknut_WG4005415534_ATEEN0518.jpg'),require('@/assets/pro/1/Locknut_WG4005415534/Locknut_WG4005415534_ATEEN0516.jpg'),require('@/assets/pro/1/Locknut_WG4005415534/Locknut_WG4005415534_ATEEN32906.jpg'),]
            },
        ]
    },
    {
        name: '螺栓小零件',
        key: '2',
        url: require('@/assets/pro/2/Bolt.jpg'),
        english: 'Bolt&finding',
        detail: [
            {
                name: 'ABS支架HD90009410937',
                english: 'ABSBracket HD90009410937',
                url: require('@/assets/pro/2/ABSBracket_HD90009410937/ABSBracket_HD90009410937_ATEEN32678.jpg'),
                paramsUrl: require("@/assets/pro/2/ABSBracket_HD90009410937/ABSBracket_HD90009410937_13_56_03.png"),
                detailImgUrl: [require('@/assets/pro/2/ABSBracket_HD90009410937/ABSBracket_HD90009410937_ATEEN32678.jpg'),require('@/assets/pro/2/ABSBracket_HD90009410937/ABSBracket_HD90009410937_ATEEN32680.jpg'),require('@/assets/pro/2/ABSBracket_HD90009410937/ABSBracket_HD90009410937_ATEEN32676.jpg'),]

            },
            {
                name: '车轮螺栓150A22133CZF2',
                english: 'Wheel Bolt 150A22133CZF2',
                url: require('@/assets/pro/2/Wheel_Bolt_150A22133CZF2/Wheel_Bolt_150A22133CZF2_ATEEN32769.jpg'),
                paramsUrl:require("@/assets/pro/2/Wheel_Bolt_150A22133CZF2/Wheel_Bolt_150A22133CZF2_14_08_12.png"),
                detailImgUrl: [require('@/assets/pro/2/Wheel_Bolt_150A22133CZF2/Wheel_Bolt_150A22133CZF2_ATEEN32769.jpg'),require('@/assets/pro/2/Wheel_Bolt_150A22133CZF2/Wheel_Bolt_150A22133CZF2_ATEEN32767.jpg'),],
            },
            {
                name: '车轮螺栓H150A2284AZF3',
                english: 'Wheel Bolt H150A2284AZF3',
                url: require('@/assets/pro/2/Wheel_Bolt_H150A2284AZF3/Wheel_Bolt_H150A2284AZF3_ATEEN32634.jpg'),
                paramsUrl:require("@/assets/pro/2/Wheel_Bolt_H150A2284AZF3/Wheel_Bolt_H150A2284AZF3_13_52_43.png"),
                detailImgUrl: [require('@/assets/pro/2/Wheel_Bolt_H150A2284AZF3/Wheel_Bolt_H150A2284AZF3_ATEEN32638.jpg'),require('@/assets/pro/2/Wheel_Bolt_H150A2284AZF3/Wheel_Bolt_H150A2284AZF3_ATEEN32634.jpg'),],
            },
            {
                name: '车轮螺栓H150A2299AZF3',
                english: 'Wheel Bolt H150A2299AZF3',
                url: require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF3/Wheel_Bolt_H150A2299AZF3_ATEEN32631.jpg'),
                paramsUrl:require("@/assets/pro/2/Wheel_Bolt_H150A2299AZF3/Wheel_Bolt_H150A2299AZF3_135227png.png"),
                detailImgUrl: [require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF3/Wheel_Bolt_H150A2299AZF3_ATEEN32631.jpg'),require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF3/Wheel_Bolt_H150A2299AZF3_ATEEN32629.jpg'),],
            },
            {
                name: '车轮螺栓H150A2299AZF70',
                english: 'Wheel Bolt H150A2299AZF70',
                url: require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF70/Wheel_Bolt_H150A2299AZF70_ATEEN32694.jpg'),
                paramsUrl:require("@/assets/pro/2/Wheel_Bolt_H150A2299AZF70/Wheel_Bolt_H150A2299AZF70_135649.png"),
                detailImgUrl: [require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF70/Wheel_Bolt_H150A2299AZF70_ATEEN32694.jpg'),require('@/assets/pro/2/Wheel_Bolt_H150A2299AZF70/Wheel_Bolt_H150A2299AZF70_ATEEN32693.jpg'),],
            },
            {
                name: '车轮螺栓H150A22120AZF3',
                english: 'Wheel Bolt H150A22120AZF3',
                url: require('@/assets/pro/2/Wheel_Bolt_H150A22120AZF3/Wheel_Bolt_H150A22120AZF3_ATEEN32655.jpg'),
                paramsUrl:require("@/assets/pro/2/Wheel_Bolt_H150A22120AZF3/Wheel_Bolt_H150A22120AZF3_135349.png"),
                detailImgUrl: [require('@/assets/pro/2/Wheel_Bolt_H150A22120AZF3/Wheel_Bolt_H150A22120AZF3_ATEEN32655.jpg'),require('@/assets/pro/2/Wheel_Bolt_H150A22120AZF3/Wheel_Bolt_H150A22120AZF3_ATEEN32653.jpg'),],
            },
            {
                name: '吊耳29AD-01020-A',
                english: 'Lifting Lug 29AD-01020-A',
                url: require('@/assets/pro/2/Lifting_Lug_29AD-01020-A/Lifting_Lug_29AD-01020-A_ATEEN32864.jpg'),
                paramsUrl:require("@/assets/pro/2/Lifting_Lug_29AD-01020-A/Lifting_Lug_29AD-01020-A_13_42_23.png"),
                detailImgUrl: [require('@/assets/pro/2/Lifting_Lug_29AD-01020-A/Lifting_Lug_29AD-01020-A_ATEEN32864.jpg'),require('@/assets/pro/2/Lifting_Lug_29AD-01020-A/Lifting_Lug_29AD-01020-A_ATEEN32862.jpg'),],
            },
            {
                name: '滚轮AZ711745416895',
                english: 'Idler Wheel AZ711745416895',
                url: require('@/assets/pro/2/Idler_Wheel_AZ711745416895/Idler_Wheel_AZ711745416895_ATEEN32665.jpg'),
                paramsUrl:require("@/assets/pro/2/Idler_Wheel_AZ711745416895/Idler_Wheel_AZ711745416895_13_55_31.png"),
                detailImgUrl: [require('@/assets/pro/2/Idler_Wheel_AZ711745416895/Idler_Wheel_AZ711745416895_ATEEN32665.jpg'),require('@/assets/pro/2/Idler_Wheel_AZ711745416895/Idler_Wheel_AZ711745416895_ATEEN32664.jpg'),],
            },
            {
                name: '滚轮WG976150120',
                english: 'Idler Wheel WG976150120',
                url: require('@/assets/pro/2/Idler_Wheel_WG976150120/Idler_Wheel_WG976150120_ATEEN32672.jpg'),
                paramsUrl:require("@/assets/pro/2/Idler_Wheel_WG976150120/Idler_Wheel_WG976150120_13_55_44.png"),
                detailImgUrl: [require('@/assets/pro/2/Idler_Wheel_WG976150120/Idler_Wheel_WG976150120_ATEEN32672.jpg'),require('@/assets/pro/2/Idler_Wheel_WG976150120/Idler_Wheel_WG976150120_ATEEN32667.jpg'),],
            },
            {
                name: '滚轮轴WG9761450132',
                english: 'Roller Shaft WG9761450132',
                url: require('@/assets/pro/2/Roller_Shaft_WG9761450132/Roller_Shaft_WG9761450132_ATEEN32660.jpg'),
                paramsUrl:require("@/assets/pro/2/Roller_Shaft_WG9761450132/Roller_Shaft_WG9761450132_13_54_17.png"),
                detailImgUrl: [require('@/assets/pro/2/Roller_Shaft_WG9761450132/Roller_Shaft_WG9761450132_ATEEN32660.jpg'),require('@/assets/pro/2/Roller_Shaft_WG9761450132/Roller_Shaft_WG9761450132_ATEEN32656.jpg'),],
            },
            {
                name: '后板簧吊耳295AMA00010',
                english: 'Spring Shackle 295AMA00010',
                url: require('@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_ATEEN32878.jpg'),
                paramsUrl:require("@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_13_47_51.png"),
                detailImgUrl: [require('@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_ATEEN32878.jpg'),require('@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_ATEEN32877.jpg'),require('@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_ATEEN0545.jpg'),require('@/assets/pro/2/Spring_Shackle_295AMA00010/Spring_Shackle_295AMA00010_ATEEN0538.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓DZ90001500006',
                english: 'Hexagon Flange Bearing Toothed Bolt DZ9000150000',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006_ATEEN32729.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006_14_05_39.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006_ATEEN32729.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90001500006_ATEEN32727.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓DZ90149326001',
                english: 'Hexagon Flange Bearing Toothed Bolt DZ90149326001',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001_ATEEN32745.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001_14_06_42.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001_ATEEN32745.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001/Hexagon_Flange_Bearing_Toothed_Bolt_DZ90149326001_ATEEN32739.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓HD90001500038',
                english: 'Hexagon Flange Bearing Toothed Bolt HD90001500038',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038_ATEEN32771.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038_14_08_30.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038_ATEEN32771.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038/Hexagon_Flange_Bearing_Toothed_Bolt_HD90001500038_ATEEN32770.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓HD90149320363',
                english: 'Hexagon Flange Bearing Toothed Bolt HD90149320363',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363_ATEEN32725.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363_14_05_20.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363_ATEEN32725.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149320363_ATEEN32720.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓HD90149326156',
                english: 'Hexagon Flange Bearing Toothed Bolt HD90149326156',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156_ATEEN32797.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156_ATEEN32797.jpg"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156_ATEEN32797.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326156_ATEEN32797.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓HD90149326381',
                english: 'Hexagon Flange Bearing Toothed Bolt HD90149326381',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381_ATEEN32762.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381_14_07_35.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381_ATEEN32762.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326381_ATEEN32761.jpg'),],
            },
            {
                name: '六角法兰承面带齿螺栓HD90149326382',
                english: 'Hexagon Flange Bearing Toothed Bolt HD90149326382',
                url: require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382_ATEEN32760.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382_14_07_15.png"),
                detailImgUrl: [ require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382_ATEEN32760.jpg'), require('@/assets/pro/2/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382/Hexagon_Flange_Bearing_Toothed_Bolt_HD90149326382_ATEEN32757.jpg'),],
            },
            {
                name: '六角法兰面螺栓HD90001500057',
                english: 'Hexagon Flange Bolt HD90001500057',
                url: require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90001500057/Hexagon_Flange_Bolt_HD90001500057_ATEEN32734.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bolt_HD90001500057/Hexagon_Flange_Bolt_HD90001500057_14_06_04.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90001500057/Hexagon_Flange_Bolt_HD90001500057_ATEEN32734.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90001500057/Hexagon_Flange_Bolt_HD90001500057_ATEEN32733.jpg'),],
            },
            {
                name: '六角法兰面螺栓HD90129348660',
                english: 'Hexagon Flange Bolt HD90129348660',
                url: require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348660/Hexagon_Flange_Bolt_HD90129348660_ATEEN32710.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348660/Hexagon_Flange_Bolt_HD90129348660_14_05_00.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348660/Hexagon_Flange_Bolt_HD90129348660_ATEEN32710.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348660/Hexagon_Flange_Bolt_HD90129348660_ATEEN32708.jpg'),],
            },
            {
                name: '六角法兰面螺栓HD90129348661',
                english: 'Hexagon Flange Bolt HD90129348661',
                url: require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348661/Hexagon_Flange_Bolt_HD90129348661_ATEEN32702.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348661/Hexagon_Flange_Bolt_HD90129348661_13_57_10.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348661/Hexagon_Flange_Bolt_HD90129348661_ATEEN32702.jpg'),require('@/assets/pro/2/Hexagon_Flange_Bolt_HD90129348661/Hexagon_Flange_Bolt_HD90129348661_ATEEN32697.jpg'),],
            },
            {
                name: '六角头螺栓DZ9114520116',
                english: 'Hexagon Bolt DZ9114520116',
                url: require('@/assets/pro/2/Hexagon_Bolt_DZ9114520116/Hexagon_Bolt_DZ9114520116_ATEEN32789.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Bolt_DZ9114520116/Hexagon_Bolt_DZ9114520116_140955.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Bolt_DZ9114520116/Hexagon_Bolt_DZ9114520116_ATEEN32789.jpg'),require('@/assets/pro/2/Hexagon_Bolt_DZ9114520116/Hexagon_Bolt_DZ9114520116_ATEEN32788.jpg'),],
            },
            {
                name: '六角头螺栓DZ9114520117',
                english: 'Hexagon Bolt DZ9114520117',
                url: require('@/assets/pro/2/Hexagon_Bolt_DZ9114520117/Hexagon_Bolt_DZ9114520117_ATEEN32792.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Bolt_DZ9114520117/Hexagon_Bolt_DZ9114520117_141004.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Bolt_DZ9114520117/Hexagon_Bolt_DZ9114520117_ATEEN32792.jpg'),require('@/assets/pro/2/Hexagon_Bolt_DZ9114520117/Hexagon_Bolt_DZ9114520117_ATEEN32791.jpg'),],
            },
            {
                name: '六角头螺栓Q151B1865TF2',
                english: 'Hexagon Bolt Q151B1865TF2',
                url: require('@/assets/pro/2/Hexagon_Bolt_Q151B1865TF2/Hexagon_Bolt_Q151B1865TF2_ATEEN32736.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Bolt_Q151B1865TF2/Hexagon_Bolt_Q151B1865TF2_140626.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Bolt_Q151B1865TF2/Hexagon_Bolt_Q151B1865TF2_ATEEN32736.jpg'),require('@/assets/pro/2/Hexagon_Bolt_Q151B1865TF2/Hexagon_Bolt_Q151B1865TF2_ATEEN32735.jpg'),],
            },
            {
                name: '六角头螺栓Q151B2070TF2',
                english: 'Hexagon Bolt Q151B2070TF2',
                url: require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF2/Hexagon_Bolt_Q151B2070TF2_ATEEN32766.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Bolt_Q151B2070TF2/Hexagon_Bolt_Q151B2070TF2_140753.png"),
                detailImgUrl: [ require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF2/Hexagon_Bolt_Q151B2070TF2_ATEEN32766.jpg'), require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF2/Hexagon_Bolt_Q151B2070TF2_ATEEN32765.jpg'),],
            },
            {
                name: '六角头螺栓Q151B2070TF3',
                english: 'Hexagon Bolt Q151B2070TF3',
                url: require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF3/Hexagon_Bolt_Q151B2070TF3_ATEEN32652.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Bolt_Q151B2070TF3/Hexagon_Bolt_Q151B2070TF3_Hexagon_Bolt_Q151B2070TF3_135306.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF3/Hexagon_Bolt_Q151B2070TF3_ATEEN32652.jpg'),require('@/assets/pro/2/Hexagon_Bolt_Q151B2070TF3/Hexagon_Bolt_Q151B2070TF3_ATEEN32641.jpg'),],
            },
            {
                name: '螺栓HD90129324298',
                english: 'Blot HD90129324298',
                url: require('@/assets/pro/2/Blot_HD90129324298/Blot_HD90129324298_ATEEN32783.jpg'),
                paramsUrl:require("@/assets/pro/2/Blot_HD90129324298/Blot_HD90129324298_14_09_24.png"),
                detailImgUrl: [require('@/assets/pro/2/Blot_HD90129324298/Blot_HD90129324298_ATEEN32783.jpg'),require('@/assets/pro/2/Blot_HD90129324298/Blot_HD90129324298_ATEEN32780.jpg'),],
            },
            {
                name: '螺纹销HD90009440669',
                english: 'Threaded Pin HD90009440669',
                url: require('@/assets/pro/2/Threaded_Pin_HD90009440669/Threaded_Pin_HD90009440669_ATEEN32776.jpg'),
                paramsUrl:require("@/assets/pro/2/Threaded_Pin_HD90009440669/Threaded_Pin_HD90009440669_ATEEN32776_14_09_04.png"),
                detailImgUrl: [require('@/assets/pro/2/Threaded_Pin_HD90009440669/Threaded_Pin_HD90009440669_ATEEN32776.jpg'),],
            },
            {
                name: '内六角圆柱头螺栓DZ9112340388',
                english: 'Hexagon Socket Cap Head Screws DZ9112340388',
                url: require('@/assets/pro/2/Hexagon_Socket_Cap_Head_Screws_DZ9112340388/Hexagon_Socket_Cap_Head_Screws_DZ9112340388_ATEEN32752.jpg'),
                paramsUrl:require("@/assets/pro/2/Hexagon_Socket_Cap_Head_Screws_DZ9112340388/Hexagon_Socket_Cap_Head_Screws_DZ9112340388_14_07_01.png"),
                detailImgUrl: [require('@/assets/pro/2/Hexagon_Socket_Cap_Head_Screws_DZ9112340388/Hexagon_Socket_Cap_Head_Screws_DZ9112340388_ATEEN32752.jpg'),require('@/assets/pro/2/Hexagon_Socket_Cap_Head_Screws_DZ9112340388/Hexagon_Socket_Cap_Head_Screws_DZ9112340388_ATEEN32749.jpg'),require('@/assets/pro/2/Hexagon_Socket_Cap_Head_Screws_DZ9112340388/Hexagon_Socket_Cap_Head_Screws_DZ9112340388_ATEEN32746.jpg'),],
            },
            {
                name: '托架总成990.14.32.0156',
                english: 'Bracket Assy 990.14.32.0156',
                url: require('@/assets/pro/2/Bracket_Assy_990_14_32_0156/Bracket_Assy_990_14_32_0156_ATEEN32841.jpg'),
                paramsUrl:require("@/assets/pro/2/Bracket_Assy_990_14_32_0156/Bracket_Assy_990_14_32_0156_15_13_54.png"),
                detailImgUrl: [require('@/assets/pro/2/Bracket_Assy_990_14_32_0156/Bracket_Assy_990_14_32_0156_ATEEN32841.jpg'),require('@/assets/pro/2/Bracket_Assy_990_14_32_0156/Bracket_Assy_990_14_32_0156_ATEEN32839.jpg'),],
            },
            {
                name: '限位螺钉HD90009411332',
                english: 'Stop Screw HD90009411332',
                url: require('@/assets/pro/2/Stop_Screw_HD90009411332/Stop_Screw_HD90009411332_ATEEN32774.jpg'),
                paramsUrl:require("@/assets/pro/2/Stop_Screw_HD90009411332/Stop_Screw_HD90009411332_14_08_52.png"),
                detailImgUrl: [require('@/assets/pro/2/Stop_Screw_HD90009411332/Stop_Screw_HD90009411332_ATEEN32774.jpg'),require('@/assets/pro/2/Stop_Screw_HD90009411332/Stop_Screw_HD90009411332_ATEEN32773.jpg'),],
            },
            {
                name: '右托架29AD-05124',
                english: 'Right Bracket 29AD-05124',
                url: require('@/assets/pro/2/Right_Bracket_29AD_05124_05124/Right_Bracket_29AD-05124_ATEEN32881.jpg'),
                paramsUrl:require("@/assets/pro/2/Right_Bracket_29AD_05124_05124/Right_Bracket_29AD-05124_15_41_37.png"),
                detailImgUrl: [require('@/assets/pro/2/Right_Bracket_29AD_05124_05124/Right_Bracket_29AD-05124_ATEEN32881.jpg'),require('@/assets/pro/2/Right_Bracket_29AD_05124_05124/Right_Bracket_29AD-05124_ATEEN32879.jpg'),require('@/assets/pro/2/Right_Bracket_29AD_05124_05124/Right_Bracket_29AD-05124_ATEEN0575.jpg'),],
            },
            {
                name: '圆柱销HD90129520040',
                english: 'Cylindrical Pin HD90129520040',
                url: require('@/assets/pro/2/Cylindrical_Pin_HD90129520040/Cylindrical_Pin_HD90129520040_ATEEN32784.jpg'),
                paramsUrl:require("@/assets/pro/2/Cylindrical_Pin_HD90129520040/Cylindrical_Pin_HD90129520040_14_09_39.png"),
                detailImgUrl: [require('@/assets/pro/2/Cylindrical_Pin_HD90129520040/Cylindrical_Pin_HD90129520040_ATEEN32784.jpg'),],
            },
        ]
    },
    {
        name: '主销',
        key: '3',
        url: require('@/assets/pro/3/Kingpin.jpg'),
        english: 'Kingpin',
        detail: [
            {
                name: '上主销81.36305.0015',
                english: 'Upper King Pin 81.36305.0015',
                url: require('@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_ATEEN32819.jpg'),
                paramsUrl: require("@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_15_07_22.png"),
                detailImgUrl: [require('@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_ATEEN32819.jpg'),require('@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_ATEEN32818.jpg'),require('@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_ATEEN32816.jpg'),require('@/assets/pro/3/Upper_King_Pin_81_36305_0015/Upper_King_Pin_81_36305_0015_ATEEN32815.jpg'),]
            },
            {
                name: '蹄销81.502120032',
                english: 'Shoe Pin 81.502120032',
                url: require('@/assets/pro/3/Shoe_Pin_81_502120032/Shoe_Pin_81_502120032_ATEEN32685.jpg'),
                paramsUrl: require("@/assets/pro/3/Shoe_Pin_81_502120032/Shoe_Pin_81_502120032_13_56_32.png"),
                detailImgUrl: [require('@/assets/pro/3/Shoe_Pin_81_502120032/Shoe_Pin_81_502120032_ATEEN32685.jpg'),require('@/assets/pro/3/Shoe_Pin_81_502120032/Shoe_Pin_81_502120032_ATEEN32682.jpg'),]
            },
            {
                name: '下主销81.91020.0709',
                english: 'Lower King Pin 81.91020.0709',
                url: require('@/assets/pro/3/PLower_King_Pin_81_91020_0709/PLower_King_Pin_81_91020_0709_ATEEN32804.jpg'),
                paramsUrl: require("@/assets/pro/3/PLower_King_Pin_81_91020_0709/PLower_King_Pin_81_91020_0709_14_10_48.png"),
                detailImgUrl: [require('@/assets/pro/3/PLower_King_Pin_81_91020_0709/PLower_King_Pin_81_91020_0709_ATEEN32804.jpg'),require('@/assets/pro/3/PLower_King_Pin_81_91020_0709/PLower_King_Pin_81_91020_0709_ATEEN32801.jpg'),require('@/assets/pro/3/PLower_King_Pin_81_91020_0709/PLower_King_Pin_81_91020_0709_ATEEN32800.jpg'),]
            },
            {
                name: '销轴HD90009418175',
                english: 'Pin Sharft HD90009418175',
                url: require('@/assets/pro/3/Pin_Sharft_HD90009418175/Pin_Sharft_HD90009418175_ATEEN32837.jpg'),
                paramsUrl: require("@/assets/pro/3/Pin_Sharft_HD90009418175/Pin_Sharft_HD90009418175_15_13_37.png"),
                detailImgUrl: [require('@/assets/pro/3/Pin_Sharft_HD90009418175/Pin_Sharft_HD90009418175_ATEEN32837.jpg'),require('@/assets/pro/3/Pin_Sharft_HD90009418175/Pin_Sharft_HD90009418175_ATEEN32836.jpg'),]
            },
            {
                name: '行星轮轴81.91020.0295',
                english: 'Planetary Shaft 81.91020.0295',
                url: require('@/assets/pro/3/Planetary_Shaft_81_91020_0295/Planetary_Shaft_81_91020_0295_ATEEN32822.jpg'),
                paramsUrl: require("@/assets/pro/3/Planetary_Shaft_81_91020_0295/Planetary_Shaft_81_91020_0295_15_08_26.png"),
                detailImgUrl: [require('@/assets/pro/3/Planetary_Shaft_81_91020_0295/Planetary_Shaft_81_91020_0295_ATEEN32822.jpg'),require('@/assets/pro/3/Planetary_Shaft_81_91020_0295/Planetary_Shaft_81_91020_0295_ATEEN32821.jpg'),]
            },
            {
                name: '行星轮轴81.91020.0709',
                english: 'Planetary Shaft 81.91020.0709',
                url: require('@/assets/pro/3/Planetary_Shaft_81_91020_0709/Planetary_Shaft_81_91020_0709_ATEEN32799.jpg'),
                paramsUrl: require("@/assets/pro/3/Planetary_Shaft_81_91020_0709/Planetary_Shaft_81_91020_0709_14_10_35.png"),
                detailImgUrl: [require('@/assets/pro/3/Planetary_Shaft_81_91020_0709/Planetary_Shaft_81_91020_0709_ATEEN32799.jpg'),require('@/assets/pro/3/Planetary_Shaft_81_91020_0709/Planetary_Shaft_81_91020_0709_ATEEN32798.jpg'),]
            },
            {
                name: '主销AZ4071415005',
                english: 'King Pin AZ4071415005',
                url: require('@/assets/pro/3/King_Pin_AZ4071415005/King_Pin_AZ4071415005_ATEEN32826.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_AZ4071415005/King_Pin_AZ4071415005_15_08_48.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_AZ4071415005/King_Pin_AZ4071415005_ATEEN32826.jpg'),]
            },
            {
                name: '主销DZ90009410095',
                english: 'King PinDZ90009410095',
                url: require('@/assets/pro/3/King_PinDZ90009410095/King_PinDZ90009410095_ATEEN32835.jpg'),
                paramsUrl: require("@/assets/pro/3/King_PinDZ90009410095/King_PinDZ90009410095_15_13_24.png"),
                detailImgUrl: [require('@/assets/pro/3/King_PinDZ90009410095/King_PinDZ90009410095_ATEEN32835.jpg'),]
            },
            {
                name: '主销HD90009410837',
                english: 'King Pin HD90009410837',
                url: require('@/assets/pro/3/King_Pin_HD90009410837/King_Pin_HD90009410837_ATEEN32824.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_HD90009410837/King_Pin_HD90009410837_15_08_37.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_HD90009410837/King_Pin_HD90009410837_ATEEN32824.jpg'),require('@/assets/pro/3/King_Pin_HD90009410837/King_Pin_HD90009410837_ATEEN32823.jpg'),]
            },
            {
                name: '主销HD90009411198',
                english: 'King Pin HD90009411198',
                url: require('@/assets/pro/3/King_Pin_HD90009411198/King_Pin_HD90009411198_ATEEN32827.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_HD90009411198/King_Pin_HD90009411198_15_09_56.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_HD90009411198/King_Pin_HD90009411198_ATEEN32827.jpg')]
            },
            {
                name: '主销HD90009412700',
                english: 'King Pin HD90009412700',
                url: require('@/assets/pro/3/King_Pin_HD90009412700/King_Pin_HD90009412700_ATEEN32831.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_HD90009412700/King_Pin_HD90009412700_15_10_49.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_HD90009412700/King_Pin_HD90009412700_ATEEN32831.jpg'),]
            },
            {
                name: '主销HD90009418426',
                english: 'King Pin HD90009418426',
                url: require('@/assets/pro/3/King_Pin_HD90009418426/King_Pin_HD90009418426_ATEEN32834.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_HD90009418426/King_Pin_HD90009418426_15_10_59.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_HD90009418426/King_Pin_HD90009418426_ATEEN32834.jpg'),]
            },
            {
                name: '主销HD95009410054',
                english: 'King Pin HD95009410054',
                url: require('@/assets/pro/3/King_Pin_HD95009410054/King_Pin_HD95009410054_ATEEN32829.jpg'),
                paramsUrl: require("@/assets/pro/3/King_Pin_HD95009410054/King_Pin_HD95009410054_15_10_31.png"),
                detailImgUrl: [require('@/assets/pro/3/King_Pin_HD95009410054/King_Pin_HD95009410054_ATEEN32829.jpg'),]
            },
            {
                name: '转向节销DZ9112410170',
                english: 'Knuckle Spindle DZ9112410170',
                url: require('@/assets/pro/3/Knuckle_Spindle_DZ9112410170/Knuckle_Spindle_DZ9112410170_ATEEN32814.jpg'),
                paramsUrl: require("@/assets/pro/3/Knuckle_Spindle_DZ9112410170/Knuckle_Spindle_DZ9112410170_15_06_57.png"),
                detailImgUrl: [require('@/assets/pro/3/Knuckle_Spindle_DZ9112410170/Knuckle_Spindle_DZ9112410170_ATEEN32814.jpg'),require('@/assets/pro/3/Knuckle_Spindle_DZ9112410170/Knuckle_Spindle_DZ9112410170_ATEEN32813.jpg'),require('@/assets/pro/3/Knuckle_Spindle_DZ9112410170/Knuckle_Spindle_DZ9112410170_ATEEN32812.jpg'),]
            },
            {
                name: '转向节销DZ9112410171',
                english: 'Knuckle Spindle DZ9112410171',
                url: require('@/assets/pro/3/Knuckle_Spindle_DZ9112410171/Knuckle_Spindle_DZ9112410171_ATEEN32809.jpg'),
                paramsUrl: require("@/assets/pro/3/Knuckle_Spindle_DZ9112410171/Knuckle_Spindle_DZ9112410171_14_11_04.png"),
                detailImgUrl: [require('@/assets/pro/3/Knuckle_Spindle_DZ9112410171/Knuckle_Spindle_DZ9112410171_ATEEN32809.jpg'),require('@/assets/pro/3/Knuckle_Spindle_DZ9112410171/Knuckle_Spindle_DZ9112410171_ATEEN32808.jpg'),require('@/assets/pro/3/Knuckle_Spindle_DZ9112410171/Knuckle_Spindle_DZ9112410171_ATEEN32807.jpg'),]
            },
        ]
    },
    {
        name: '转向节臂',
        key: '4',
        url: require('@/assets/pro/4/Steering_Knuckle_Arm.jpg'),
        english: 'Steering Knuckle Arm',
        detail: [
            {
                name: '转向节臂30N-01041',
                english: 'Steering Knuckle Arm 30N-01041',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_ATEEN32888.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_13_48_33.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_ATEEN32886.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_ATEEN0588.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_ATEEN0587.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N-01041/Steering_Knuckle_Arm_30N-01041_ATEEN32888.jpg'),]
            },
            {
                name: '转向节臂30N-01042FT2',
                english: 'Steering Knuckle Arm 30N-01042FT2',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_ATEEN32869.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_13_42_54.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_ATEEN32868.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_ATEEN0582.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_ATEEN0580.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_30N_01042FT2/Steering_Knuckle_Arm_30N-01042FT2_ATEEN32869.jpg'),]
            },
            {
                name: '转向节臂880.41.0041-2',
                english: 'Steering Knuckle Arm 880.41.0041-2',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_ATEEN32872.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_13_43_17.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_ATEEN32872.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_ATEEN32870.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_ATEEN0577.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_880_41_0041_2/Steering_Knuckle_Arm_880_41_0041_2_ATEEN0576.jpg'),]
            },
            {
                name: '转向节臂3001031-A1H',
                english: 'Steering Knuckle Arm 3001031-A1H',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN32856.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_13_40_48.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN32853.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN0590.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN0589.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN0559.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001031_A1H/Steering_Knuckle_Arm_3001031_A1H_ATEEN32856.jpg'),]
            },
            {
                name: '转向节臂3001044-KRCG0',
                english: 'Steering Knuckle Arm 3001044-KRCG0',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_3001044_KRCG0/Steering_Knuckle_Arm_3001044_KRCG0_ATEEN32857.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_3001044_KRCG0/Steering_Knuckle_Arm_3001044_KRCG0_13_41_15.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_3001044_KRCG0/Steering_Knuckle_Arm_3001044_KRCG0_ATEEN0557.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001044_KRCG0/Steering_Knuckle_Arm_3001044_KRCG0_ATEEN0556.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_3001044_KRCG0/Steering_Knuckle_Arm_3001044_KRCG0_ATEEN32857.jpg'),]
            },
            {
                name: '转向节臂HD90009410623',
                english: 'Steering Knuckle Arm HD90009410623',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_HD90009410623/Steering_Knuckle_Arm_HD90009410623_ATEEN32899.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_HD90009410623/Steering_Knuckle_Arm_HD90009410623_13_49_02.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_HD90009410623/Steering_Knuckle_Arm_HD90009410623_ATEEN32899.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_HD90009410623/Steering_Knuckle_Arm_HD90009410623_ATEEN0533.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_HD90009410623/Steering_Knuckle_Arm_HD90009410623_ATEEN0532.jpg'),]
            },
            {
                name: '转向节臂WG4007410031',
                english: 'Steering Knuckle Arm WG4007410031',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_WG4007410031/Steering_Knuckle_Arm_WG4007410031_ATEEN32867.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_WG4007410031/Steering_Knuckle_Arm_WG4007410031_13_42_42.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_WG4007410031/Steering_Knuckle_Arm_WG4007410031_ATEEN32867.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4007410031/Steering_Knuckle_Arm_WG4007410031_ATEEN0555.jpg'),]
            },
            {
                name: '转向节臂WG4071415053',
                english: 'Steering Knuckle Arm WG4071415053',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_ATEEN32885.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_13_48_07.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_ATEEN32884.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_ATEEN0561.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_ATEEN0560.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415053/Steering_Knuckle_Arm_WG4071415053_ATEEN32885.jpg'),]
            },
            {
                name: '转向节臂WG4071415057',
                english: 'Steering Knuckle Arm WG4071415057',
                url: require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN32889.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_13_48_50.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN32865.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN0510.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN0509.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN0509_B.jpg'),require('@/assets/pro/4/Steering_Knuckle_Arm_WG4071415057/Steering_Knuckle_Arm_WG4071415057_ATEEN32889.jpg'),]
            },
            {
                name: '转向节上臂3001044-A1H',
                english: 'Steering knuckle upper arm 3001044-A1H',
                url: require('@/assets/pro/4/Steering_knuckle_upper_arm_3001044_A1H/Steering_knuckle_upper_arm_3001044_A1H_ATEEN32907.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_knuckle_upper_arm_3001044_A1H/Steering_knuckle_upper_arm_3001044_A1H_13_49_19.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_knuckle_upper_arm_3001044_A1H/Steering_knuckle_upper_arm_3001044_A1H_ATEEN32907.jpg'),require('@/assets/pro/4/Steering_knuckle_upper_arm_3001044_A1H/Steering_knuckle_upper_arm_3001044_A1H_ATEEN0535.jpg'),require('@/assets/pro/4/Steering_knuckle_upper_arm_3001044_A1H/Steering_knuckle_upper_arm_3001044_A1H_ATEEN0534.jpg'),]
            },
            {
                name: '转向梯形臂HD90009410191',
                english: 'Steering Tie Rod HD90009410191',
                url: require('@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_ATEEN32859.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_13_41_58.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_ATEEN32858.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_ATEEN0603.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_ATEEN0602.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009410191/Steering_Tie_Rod_HD90009410191_ATEEN32859.jpg'),]
            },
            {
                name: '转向梯形臂HD90009419027',
                english: 'Steering Tie Rod HD90009419027',
                url: require('@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_ATEEN32861.jpg'),
                paramsUrl: require("@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_13_42_11.png"),
                detailImgUrl: [require('@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_ATEEN32861.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_ATEEN32860.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_ATEEN0605.jpg'),require('@/assets/pro/4/Steering_Tie_Rod_HD90009419027/Steering_Tie_Rod_HD90009419027_ATEEN0604.jpg'),]
            },
            {
                name: '左转向节臂3001031-A1H',
                english: 'Left Steering Knuckle Arm 3001031-A1H',
                url: require('@/assets/pro/4/Left_Steering_Knuckle_Arm_3001031_A1H/Left_Steering_Knuckle_Arm_3001031_A1H_ATEEN32876.jpg'),
                paramsUrl: require("@/assets/pro/4/Left_Steering_Knuckle_Arm_3001031_A1H/Left_Steering_Knuckle_Arm_3001031_A1H_13_39_43.png"),
                detailImgUrl: [require('@/assets/pro/4/Left_Steering_Knuckle_Arm_3001031_A1H/Left_Steering_Knuckle_Arm_3001031_A1H_ATEEN32876.jpg'),require('@/assets/pro/4/Left_Steering_Knuckle_Arm_3001031_A1H/Left_Steering_Knuckle_Arm_3001031_A1H_ATEEN0594.jpg'),require('@/assets/pro/4/Left_Steering_Knuckle_Arm_3001031_A1H/Left_Steering_Knuckle_Arm_3001031_A1H_ATEEN0593.jpg'),]
            },
        ]
    }
]