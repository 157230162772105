<template>
  <div>
    <HelloWorld @toRoute="backTop" />
    <div style="width: 100%; padding: 220px 40px 0 40px">
      <el-row :gutter="24">
        <el-col :span="12">
          <div style="border: 5px solid black; width: 100%; height: 400px">
            <img
              :src="urlvalue"
              style="width: 100%; height: 100%; object-fit: contain"
            />
          </div>
          <div
            style="
              height: 150px;
              background: black;
              display: flex;
              width: 100%;
              overflow: hidden;
              overflow-x: auto;
              text-align: left;
              padding: 5px;
            "
          >
            <img
              v-for="(p, i) in list.detailImgUrl"
              :key="i"
              :src="p"
              alt=""
              style="
                width: 150px;
                height: 150px;
                margin-right: 5px;
                cursor: pointer;
                object-fit: contain;
              "
              @click="handleClick(p)"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%">
            <div
              style="
                font-size: 44px;
                color: #26314d;
                text-align: left;
                border-left: 4px solid #26314d;
                padding-left: 10px;
                height: 550px;
              "
            >
              <img
                :src="list.paramsUrl"
                style="width: 100%; height: 100%; object-fit: contain"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body_top" id="Contact" ref="Contact">
        <div style="width: 100%; height: 400px; background: #fff">
          <div
            style="
              width: 700px;
              height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 20px;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              {{ homeVal.contact[value] }}

              <!-- <div
          style="width:100px; height: 10px; background:#edcd1f"
        >
        </div> -->
            </div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="Whatsapp">
                <span>+86 15355367898</span>
              </el-form-item>
              <el-form-item label="Email">
                <span>leetuoleonardo@gmail.com</span>
              </el-form-item>
              <el-form-item label="Phonecall">
                <span>+86 15355367898</span>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld.vue";
import { homeVal } from "@/util.js";
import { mapState } from "vuex"; // 引入 mapState
export default {
  components: {
    HelloWorld,
  },
  data() {
    return {
      list: null,
      urlvalue: null,
      homeVal: homeVal,
      from: {}
    };
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
    });
    setTimeout(() => {
      loading.close();
    }, 1000);
    this.list = this.$route.query?.val;
    this.urlvalue = this.$route.query?.val.detailImgUrl[0];
  },
  computed: {
    // 使用数组作为参数
    ...mapState(["value"]),
  },
  methods: {
    backTop(id) {
      this.$router.push({
        name: "En",
        params: {
          id: id,
        },
      });
    },
    handleClick(val) {
      this.urlvalue = val;
    },
  },
};
</script>

<style scoped>
.fix11 {
  width: 80%;
  margin: 0 auto;
   position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* 确保元素在最上层 */
}
</style>
