import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const actions = {
    changeDispatch(context, value) {
        context.commit('setValue', value);
    },
};
const mutations = {
    setValue(state, payload) {
        state.value = payload
    },
};
const state = {
    value: 'english',
};

export default new Vuex.Store({
    actions,
    mutations,
    state,
});
