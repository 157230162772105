<template>
  <div>
    <HelloWorld @toRoute="backTop" />
    <div id="building">
       <div class="videoContainer">
          <video class="fullscreenVideo" id="bgVid" autoplay loop muted
            preload="auto">
            <source src="@/assets/brand/show.mp4" type="video/mp4">
          </video>
        </div>

      <div
        style="
         width: 70%;
          min-height: 400px;
          
          padding: 350px 0 0 0;
          margin: 0 auto;
        "
      >
      
      <div style="width: 100%; background: rgba(247, 247, 247, 0.8); padding: 20px 0; border-radius: 10px">
        <!-- <div
          style="
            color: black;
            font-size: 70px;
            font-weight: bold;
            margin-bottom: 20px;
          "
        >
          {{ homeVal.title[value] }}
        </div>
        <div
          style="
            color: black;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          "
        >
          {{ homeVal.titleSlogan[value] }}
        </div> -->
        <button class="button" @click="backTop('About')">{{ homeVal.titleButton[value] }}</button>
      </div>
        
      </div>
      <div class="body_top" id="About" ref="About">
        <div style="width: 100%; height: 400px; background: #fff">
          <div
            style="
              width: 70%;
              min-height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 20px;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              {{ homeVal.about[value] }}

              <!-- <div
          style="width:100px; height: 10px; background:#edcd1f"
        >
        </div> -->
            </div>
            <div style="color: black; font-size: 20px; margin-bottom: 20px">
              {{ homeVal.aboutSlogan[value] }}
            </div>
          </div>
        </div>
      </div>
      <!-- 产品 -->
      <div class="body_top" id="Product" ref="Product">
        <div
          style="
            width: 70%;
            background: rgba(247, 247, 247, 0.8);
            padding: 50px;
          "
        >
          <div
            style="
              color: black;
              width: 88%;
              margin: 0 auto;
              font-size: 45px;
              font-weight: bold;
              margin-bottom: 40px;
              justify-content: center;
              border-bottom: 8px #edcd1f solid;
              padding-left: 15px;
            "
          >
            {{ homeVal.product[value] }}
          </div>
          <el-row :gutter="40" style="padding: 0 50px">
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6"
              v-for="(p, i) in porList" :key="i"
              ><div class="card_" @click="toProductDetail(p.key)">
                <img
                  style="border-radius: 8px;width: 100%; height: 100%; object-fit: contain;"
                  class="colorize-on-hover"
                  :src="p.url"
                />
              </div>
              <div
                style="
                  width: 100%;
                  background: #fff;
                  height: 60px;
                  padding: 8px;
                  font-family: janna-lt-w20-regular, sans-serif;
                  vertical-align: baseline;
                  font-size: 20px;
                  line-height: 60px;
                "
              >
                {{ p[value] }}
              </div>
            </el-col>
          </el-row>
          <div style="color: black; font-size: 20px; margin-bottom: 20px"></div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="body_top" id="Partners" ref="Partners">
        <div style="width: 100%; height: 500px; background: #fff">
          <div
            style="
              width: 700px;
              height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              {{ homeVal.partners[value] }}
            </div>
            <div style="width: 100%;">
              <img
                class="colorize-on-hover"
                src="../assets/brand/mmexport1717057575702.png"
                style="width: 100%; height: 100%; object-fit: contain;"
                @click="product_detail(p)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 品牌发展史 -->
      <!-- <div class="body_top" id="Brand" ref="Brand">
        <div style="width: 100%; height: 400px; background: #fff">
          <div
            style="
              width: 700px;
              height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 20px;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              {{ homeVal.development[value] }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- 荣誉证书 -->
      <!-- <div class="body_top" id="certificate" ref="certificate">
        <div style="width: 100%; height: 600px; background: #fff">
          <div
            style="
              width: 700px;
              height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 200px;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              荣 誉 证 书

              
            </div>
            <div class="block">
              <viewer :images="photo">
                <el-carousel height="150px">
                  <el-carousel-item v-for="item in 4" :key="item">
                    <img
                      v-for="(src, index) in photo"
                      src="../assets/logo.png"
                      :key="index"
                      :onerror="errorImg"
                    />
                  </el-carousel-item>
                </el-carousel>
              </viewer>

              
            </div>
          </div>
        </div>
      </div> -->
      <!-- 联系我们 -->
      <div class="body_top" id="Contact" ref="Contact">
        <div style="width: 100%; height: 400px; background: #fff;">
          <div
            style="
              width: 700px;
              height: 400px;
              padding: 50px 0 0 0;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
            "
          >
            <div
              style="
                color: black;
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 20px;
                justify-content: center;
                border-bottom: 8px #edcd1f solid;
              "
            >
              {{ homeVal.contact[value] }}

              <!-- <div
          style="width:100px; height: 10px; background:#edcd1f"
        >
        </div> -->
            </div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="Whatsapp">
                <span>+86 15355367898</span>
              </el-form-item>
              <el-form-item label="Email">
                <span>leetuoleonardo@gmail.com</span>
              </el-form-item>
              <el-form-item label="Phonecall">
                <span>+86 15355367898</span>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld.vue";
import { mapState } from 'vuex'; // 引入 mapState
import { pro } from './product'
import { homeVal } from '@/util.js'
export default {
  name: "App",
  components: {
    HelloWorld,
  },
  data() {
    return {
      photo: ["../assets/logo.png"],
      homeVal: homeVal,
      porList: pro,
      form: {
        whatsapp: "",
        email: "",
        phonecall: "",
      },
      fullscreenLoading: false
    };
  },
  computed: {
        // 使用数组作为参数
        ...mapState(['value'])
  },
  watch: {
    value: {
      handler(val) {
        console.log('??',val)
      },
      deep: true,
      immediate: true
    }
  },
  
  created() {
    this.$nextTick(() => {
      if (this.$route.params?.id) this.backTop(this.$route.params?.id);
    })
  },
  methods: {
    toProductDetail(type) {
      this.$router.push({
        path: "/Product",
        query: {
          key: type,
        },
      });
    },
    backTop(id) {
      //box表示需要定位到的div
      this.$nextTick(() => {
        const scrollTop = document.getElementById(id);
        scrollTop.scrollIntoView({ behavior: 'smooth' });
      })
    },
  },
};
</script>

<style scoped>
.card_product {
  /* border: 5px solid rgb(208,207,209);
  border-radius: 8px; */
}
.card_ {
  width: 100%;
  background: rgb(208, 208, 209);
  padding: 8px;
  /* border: 5px solid rgb(208,208,209); */
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 15px;
}

.colorize-on-hover:hover {
  filter: alpha(Opacity=80);
  -moz-opacity: 0.3;
  opacity: 0.8;
}

/* #building {
  background: url("../assets/v2-3ad1e2c0ddefaa1370efb39cae51b39f_1440w.jpg");
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-attachment: fixed;
} */
.body_top {
  /* background: url("../assets/v2-3ad1e2c0ddefaa1370efb39cae51b39f_1440w.jpg"); */
  width: 100%;
  min-height: 850px;
  background-attachment: fixed;
  background-size: 100% 100%;
  display: flex; /* 使用Flexbox布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.videoContainer {
  position: fixed;
  width: 800px;
  height: 400px;
  overflow: hidden;
  z-index: -100;
}

.button {
  width: 240px;
  font-style: normal;
  font-weight: 200;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background: #edcd1f;
  font-size: 20px;
  font-family: janna-lt-w20-regular, sans-serif;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
}
.button:hover {
  background: #fff;
  color: #edcd1f;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25, 29, 34, 0.65);
}
.videoContainer{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25,29,34,.65);
}
.videoContainer video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

</style>
