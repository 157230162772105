import VueRouter from "vue-router"
import En from '../views/En.vue'
import Product from '../views/Product.vue'
import Detail from '../views/Detail'
const router=new VueRouter({
    routes:[
        //配置路由的路径
        // pc
        {
            path:'/',
            name: 'En',
            component:En,
        },
        {
            path:'/Product',
            component:Product,
        },
        {
            path:'/Detail',
            component:Detail,
        },
        // 移动
        {
            path:'/MEn',
            name: 'MEn',
            component:() => import('../views/MobileEn.vue'),
        },
        {
            path:'/MobileProduct',
            component: () => import('../views/MobileProduct.vue'),
        },
        {
            path:'/MobileDetail',
            component: () => import('../views/MobileDetail.vue'),
        },
    ]
})

export default router