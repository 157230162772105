<template>
  <!-- 导航栏 -->
  <div appHeader="" class="header-body">
    <!-- 导航信息 -->
    <div appHeader="" class="header-bottom">
      <div appHeader="" class="max-width">
        <!-- 导航左边 -->
        <div appHeader="" class="header-left" style="cursor: pointer;"  @click="routerTo()">
          <img
            src="@/assets/brand/logo.png"
            style="width: 290px; height: 290px; object-fit: cover"
            fetchpriority="high"
          />
        </div>
        <!-- 导航右边 -->
        <ul appHeader="" class="nav">
          <li v-for="(item, index) in topbar" :key="index" appHeader="" class="nav-box" @click="routerTo(item['key'])">
            <a
              appHeader=""
              aria-current="page"
              class="router-link-exact-active router-link-active"
              >{{item[value]}}</a
            >
          </li>
          <li appHeader="" class="nav-box" style="margin-left: 50px">
            <div class="content-box">
            <select @change="m1()" class="select" id="sel-opt">
              <option :value="p.value" style="" v-for="(p, i) in options" :key="i">{{ p.label }}</option>
            </select>
          </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { topbar } from '@/util.js'
import { mapState } from 'vuex'; // 引入 mapState
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      select_value: 'english',
      topbar: topbar,
      // 翻译数据
      options: [{label: 'English', value: 'english'}, {label: '中文', value: 'name'}]
      // options: [{label: 'English', value: 'english'}]
    }
  },
  computed: {
    ...mapState(['value'])
  },
  created() {
    this.$nextTick(() => {
      document.getElementById('sel-opt').value = this.value
      console.log("haha",this)
    })
  },
  methods: {
    ...mapMutations(["setValue"]),
    routerTo(id = null) {
      this.$emit('toRoute', id)
    },
    m1() {
      var oOpt = document.getElementById('sel-opt').value
      this.$store.dispatch('changeDispatch', oOpt);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
[appHeader] {
  margin: 0;
  padding: 0;
  /* width: 100%; */
}
.header-body {
  position: fixed;
  top: 0;
  z-index: 9999;
  margin: 0 auto;
  width: 100%;
}
li[appHeader] {
  list-style: none;
  cursor: pointer;
}

.wrap[appHeader] {
  margin: 0 auto;
  background: #222;
}

.new-header[appHeader] {
  width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: #fff;
  text-align: end;
}

.header-left {
  text-align: right;
  padding-top: 30px;
}

.header-up[appHeader] {
  width: 100%;
  background: #222;
  line-height: 35px;
}

.header-bottom1[appHeader],
.header-bottom[appHeader] {
  margin: 0 auto;
  color: #fff;
  background: rgb(71, 63, 63);
  position: relative;
  z-index: 11;
}

.header-bottom1[appHeader] {
  width: 1400px;
}

.max-width[appHeader] {
  width: 100%;
  height: 147px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;
}

.nav[appHeader] {
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.nav > li[appHeader] {
  width: 110px;
  text-align: center;
  height: 84px;
  line-height: 84px;
  color: #fff;
}

a[appHeader] {
  display: block;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
}

.hover-none[appHeader] {
  visibility: hidden;
  left: 330px;
  box-shadow: 0 2px 5px 1px #ccc;
}

.hover-none[appHeader],
.hover-show[appHeader] {
  position: absolute;
  top: 78px;
  background: #fff;
  padding: 10px 0;
  border-top: 4px solid #6badff;
}

.hover-show[appHeader] {
  visibility: visible;
  left: 110px;
  box-shadow: 0 4px 8px 1px #ccc;
}

.newslist[appHeader] {
  display: block;
  padding: 6px 27px;
  color: #000;
}

.newslist a[appHeader] {
  color: #333;
}

.newslist a[appHeader]:hover {
  color: #6badff;
}

.login-button[appHeader] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.login-button > img[appHeader] {
  width: 20px;
  height: 16px;
  margin-right: 5px;
}

.logo-zh[appHeader] {
  font-family: Microsoft YaHei, 微软雅黑;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 8px;
}

.logo-en[appHeader] {
  font-family: Microsoft YaHei, 微软雅黑;
  color: #fff;
  opacity: 0.8;
  font-size: 12px;
}

.nav li:nth-child(2) a[appHeader] {
  background: none;
}

select {
  padding: 8px;
  width: 80%;
  border: none;
  border-radius: 6px;
  background: rgb(59, 54, 54);
  color: #fff;
}

select:focus-visible {
  outline: none;
}

select::-ms-expand {
  display: none;
}

select option {
  border: none;
  color: #fff !important;
  font-family: Arial;
  background: rgb(59, 54, 54);
}

select::selection {
  background: rgb(59, 54, 54) !important;
}

select::-ms-expand {
  display: none;
}
select::-webkit-outer-spin-button,
select::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
