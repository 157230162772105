// 配置页面写死的地方的语言转换


// 翻译功能后期维护： 在此页面增加相应的key，value属性，比如要加法语，就加 french： ‘。。。。。’。 value就是对应的数据。然后在views--components--HelloWorld.vue文件中查询 翻译数据 。给
// 下面那一行代码加上相应的key value对象 {label: '法语', value: 'french'} 及可。

// // 产品的语言转换在文件-src--views--product.js加

// 导航栏
export const topbar = [
    {
        name: '产品 ', //中文key为name
        english: 'Product', // 英文key为english
        key: 'Product'
    },
    {
        name: '联系我们', //中文key为name
        english: 'Contact us', // 英文key为english
        key: 'Contact'
    },
    {
        name: '合作伙伴', //中文key为name
        english: 'Our partners', // 英文key为english
        key: 'Partners'
    },
    {
        name: '关于我们', //中文key为name
        english: 'About us', // 英文key为english
        key: 'About'
    },
    
]

// 首页
export const homeVal = {
    // title: {
    //     name: '力拓汽车部件',
    //     english: 'LEETUO AUTO PARTS'
    // },
    // titleSlogan: {
    //     name: '',
    //     english: ''
    //     // name: '开创性的重型设备、卡车和维护备件自1988年以来的涡轮增压器',
    //     // english: 'Pioneering spare parts of heavy equipment, trucks, and maintenance of turbochargers since 1988'
    // },
    titleButton: {
        name: '阅读更多',
        english: 'Read more'
    },
    about: {
        name: '关于我们',
        english: 'About us'
    },
    aboutSlogan: {
        name: '中国浙江力拓汽配部件有限公司创始于1988年，核心生产汽车专用的主销，转向节臂，车桥部件，高强度放松螺栓，车轮螺栓，标准件及非标准件螺栓，螺母。',
        english: 'China Zhejiang LEETUO Auto Parts Co，Ltd， was founded in 1988, the core production of special automotive kingpin,knuckle arm, automobile axle part/component,wheel bolt,high-tension loose -proof bolt,standard part and non-standard bolt/nut and each kind of product.'
    },
    product: {
        name: '产品',
        english: 'Product'
    },
    partners: {
        name: '合作伙伴',
        english: 'Our partners'
    },
    development: {
        name: '品牌发展史',
        english: 'Development'
    },
    contact: {
        name: '联系我们',
        english: 'Contact us'
    },
}
