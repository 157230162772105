<template>
  <div class="body_top">
    <HelloWorld @toRoute="backTop" />
    <div class="product">
      <div class="product_border_top"></div>
      <div class="product_border_body">
        <div class="product_border_body_content">
          <el-row
            style="display: flex; flex-wrap: wrap; "
          >
            <el-col
              v-for="(p, i) in list"
              :key="i"
              class="product_point"
              :xs="12"
              :sm="12"
              :md="12"
              :lg="6"
              :xl="6"
            >
              <img
                class="colorize-on-hover"
                :src="p.url"
                style="width: 100%; object-fit: contain"
                @click="product_detail(p)"
              />
              <!-- {{ p.name }}<br /> -->
              <!-- 下面这行是适配翻译功能，如需要此页适配，注释上面这行打开下面 -->
              {{ p[value] }}
            </el-col>
          </el-row>
        </div>
        <!-- <div class="product_border_body_left"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld.vue";
import { pro } from "./product.js";
import { mapState } from "vuex"; // 引入 mapState
export default {
  name: "Product",
  components: {
    HelloWorld,
  },
  data() {
    return {
      list: null,
    };
  },
  computed: {
    // 使用数组作为参数
    ...mapState(["value"]),
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
    });
    setTimeout(() => {
      loading.close();
    }, 2000);
    this.list = null;
    const key = this.$route.query?.key;
    pro.forEach((i) => {
      if (i.key === key) {
        this.list = i.detail;
      }
    });
  },
  methods: {
    product_detail(val) {
      this.$router.push({
        path: "/Detail",
        query: {
          val: val,
        },
      });
    },
    backTop(id) {
      this.$router.push({
        name: "En",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.body_top {
  background: rgb(143, 135, 135);
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-size: 100% 100%;
  display: flex;
  /* x轴居中 */
  justify-content: center;
  /* y轴居中 */
  align-items: flex-end;
}
.product {
  width: 70%;
  margin: 0 auto;
  background: #474747;
}
.product_border_top {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  background: linear-gradient(135deg, #292a3a, #536976);
}
.colorize-on-hover:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.colorize-on-hover {
  -moz-transition: opacity 0.7s cubic-bezier(0.2, 0.4, 0.7, 0.8);
  -webkit-transition: opacity 0.7s cubic-bezier(0.2, 0.4, 0.7, 0.8);
  transition: opacity 0.7s cubic-bezier(0.2, 0.4, 0.7, 0.8);
}

.product_border_body {
  width: 100%;
  height: 70vh;
  overflow-y: auto; /* 显示垂直滚动条 */
  margin: 0 auto;
  background: #fff;
  display: flex;
}
.product_point {
  cursor: pointer;
  font-style: normal;
  letter-spacing: 0;
  line-height: normal;
  font-size: 0.7875rem;
  font-weight: 400;
  color: #000;
}
.product_border_body_left {
  width: 20px;
  background: black;
}
.product_border_body_content {
  /* width: calc(100% - 40px); */
  width: 100%;
}
.body_title {
  display: flex;
  width: 40%;
  height: 400px;
  margin: 0 auto;
  /* x轴居中 */
  justify-content: center;
  /* y轴居中 */
  align-items: center;
}
.body_title1 {
  width: 40%;
  padding-bottom: 10px;
  /* border-bottom: 2px solid black; */
}
</style>